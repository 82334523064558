import useSWR from "swr";
import moment from "moment";
import { SORT_OPTIONS } from "../_constants";

const useMyFavorites = ({
	params,
	page,
	pageSize,
	currentSort,
	currentDate,
	getMyFavorites,
}) => {
	const handleParams = (sortValue) => {
		switch (sortValue) {
			case SORT_OPTIONS[5].value:
			case SORT_OPTIONS[6].value:
			case SORT_OPTIONS[7].value:
				return `/users/me?${
					// Current Date Filter
					currentDate !== "*"
						? `&populate[favorites][filters][startDate][$eq]=${moment(
								currentDate
						  ).format("YYYY-MM-DD")}`
						: ""
				}${
					// Current Sort Filter
					sortValue !== "none"
						? `&populate[favorites][filters][${sortValue}][$eq]=true`
						: ""
				}
        &pagination[page]=${page}&pagination[pageSize]=${pageSize}`;

			default:
				return `/users/me?${
					// Sort Filter
					sortValue !== "none" ? `populate[favorites][sort]=${sortValue}` : ""
				}${
					// Current Date Filter
					currentDate !== "*"
						? `&populate[favorites][filters][startDate][$eq]=${moment(
								currentDate
						  ).format("YYYY-MM-DD")}`
						: "&populate[favorites]=*"
				}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
		}
	};

	const { data, error, mutate } = useSWR(
		handleParams(currentSort),
		getMyFavorites
	);

	return {
		data,
		eventsData: data?.favorites,
		error,
		mutate,
	};
};

export default useMyFavorites;
