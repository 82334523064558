import useSWR from "swr";
import moment from "moment";
import { SORT_OPTIONS } from "../_constants";

const useEvents = ({
	params,
	page,
	pageSize,
	currentSort,
	currentDate,
	currentEvent,
	getEvents,
}) => {
	const handleParams = (sortValue) => {
		switch (sortValue) {
			case SORT_OPTIONS[5].value:
			case SORT_OPTIONS[6].value:
			case SORT_OPTIONS[7].value:
				return `/blue-sheet-events?${params}&pagination[page]=${page}&pagination[pageSize]=${pageSize}${
					currentDate !== "*"
						? `&filters[startDate][$eq]=${moment(currentDate).format(
								"YYYY-MM-DD"
						  )}`
						: ""
				}&filters[bluesheet][id][$eq]=${currentEvent}&filters[${sortValue}][$eq]=true`;

			default:
				return `/blue-sheet-events?${params}&pagination[page]=${page}&pagination[pageSize]=${pageSize}${
					currentDate !== "*"
						? `&filters[startDate][$eq]=${moment(currentDate).format(
								"YYYY-MM-DD"
						  )}`
						: ""
				}${
					currentSort !== "none" ? `&sort=${currentSort}` : ""
				}&filters[bluesheet][id][$eq]=${currentEvent}`;
		}
	};

	const { data, error, mutate } = useSWR(handleParams(currentSort), getEvents);

	return {
		eventsData: data,
		error,
		mutate,
	};
};

export default useEvents;
