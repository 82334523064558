import httpClient from "../utils/httpClient";

import {
	ageGroupsAdapter,
	userCountAdapter,
	usersInLast24HoursAdapter,
} from "../utils/adapters";

export const updateUser = (user) => {
	return httpClient.put(`/user/me`, user);
};

export const getAgeGroups = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		const ageGroups = ageGroupsAdapter(res.data.data);
		return { ageGroups, meta: res.data.meta };
	});
};

export const getUserCount = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		const userCount = userCountAdapter(res.data);
		return { userCount };
	});
};

export const getUsersInLast24Hours = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		const last24Hours = usersInLast24HoursAdapter(res?.data?.length);
		return { last24Hours };
	});
};

export const getMyFavorites = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		return res.data;
	});
};

export const updateFavorites = ({ eventIds }) => {
	return httpClient.put(`/user/me`, { favorites: eventIds });
};
