import React, { useMemo } from "react";
import queryString from "query-string";
import { useEvents, useMyFavorites } from "../../hooks";
import { TABLE_COLUMNS, VIEW_TYPES } from "../../_constants";
import { Table, Cards, Pagination, UserEmptyState } from "..";

import { getEvents } from "../../services/events";
import { useAuth } from "../../utils/auth";
import { getMyFavorites, updateFavorites } from "../../services/users";

import { notification } from "../../services";

const BluePrintData = ({
	view,
	page,
	pageSize,
	currentSort,
	currentDate,
	currentEvent,
	nextPage,
	prevPage,
}) => {
	const { user, refreshUser } = useAuth();

	const userFavorites = user?.favorites ?? [];

	const params = queryString.stringify(
		{
			populate: "*",
		},
		{
			encodeValuesOnly: true,
		}
	);

	const { mutate, data } = useMyFavorites({
		params,
		page,
		pageSize,
		currentSort,
		currentDate,
		getMyFavorites,
	});

	const { eventsData, mutate: mutateEvents } = useEvents({
		params,
		page,
		pageSize,
		currentSort,
		currentDate,
		currentEvent: currentEvent?.id,
		getEvents,
	});

	const columns = useMemo(
		() =>
			TABLE_COLUMNS.INITIAL_EVENT_COLUMNS.reduce(
				(acc, value) => ({ ...acc, [value]: value }),
				{}
			),
		[]
	);

	const handleFavoriteClick = async (eventId) => {
		try {
			let favorites = userFavorites.map((event) => event?.id);

			if (!!favorites.some((event) => event === eventId)) {
				// Remove from favorites
				const index = favorites.indexOf(eventId);

				if (index > -1) {
					favorites.splice(index, 1);
				}

				await updateFavorites({ userId: user?.id, eventIds: favorites });
			} else {
				// Add to favorites
				await updateFavorites({
					userId: user?.id,
					eventIds: [eventId, ...favorites],
				});
			}

			await refreshUser();

			mutate({
				data: {
					...data,
					favorites,
				},
			});
			mutateEvents();
		} catch (error) {
			notification.error("Error adding/removing favorite");
			console.error("Error adding/removing favorite", error);
		}
	};

	const renderBlueprint = () => {
		switch (view) {
			case VIEW_TYPES.TABLE:
				return (
					<Table
						columns={columns}
						data={eventsData?.events?.map((event) => {
							const newEvent = { ...event?.attributes, id: event.id };

							return newEvent;
						})}
						favorites={userFavorites.map((event) => event?.id)}
						handleFavoriteClick={handleFavoriteClick}
					/>
				);
			case VIEW_TYPES.CARDS:
				return (
					<Cards
						data={eventsData?.events?.map((event) => {
							const newEvent = { ...event?.attributes, id: event.id };

							return newEvent;
						})}
					/>
				);

			default:
				break;
		}
	};
	return (
		<>
			<div className="py-8 sm:px-0">
				{eventsData?.events?.length > 0 ? (
					renderBlueprint()
				) : (
					<UserEmptyState />
				)}
			</div>
			<Pagination
				page={page}
				perPage={pageSize}
				pageCount={eventsData?.meta?.pagination?.pageCount}
				total={eventsData?.meta?.pagination?.total}
				handleNext={nextPage}
				handlePrevious={prevPage}
			/>
		</>
	);
};

export default BluePrintData;
