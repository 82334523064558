// import { useState } from "react";
import { Outlet } from "react-router-dom";

// import { notification } from "../../services";
// import { useAuth } from "../../utils/auth";
import { AdminHeader } from "../../components";
// import httpClient from "../../utils/httpClient";

export default function AdminDashboard() {
	// const auth = useAuth();

	// if (!auth?.isAdmin) {
	// 	return <Navigate to="/profile/blueprint" />;
	// }

	return (
		<div className="py-10">
			<header>
				<div className=" mx-auto px-4 sm:px-6 lg:px-8">
					<AdminHeader />
				</div>
			</header>
			<main>
				<div className=" px-4 mx-auto sm:px-6 lg:px-8">
					{/* Replace with your content */}
					<Outlet />
					{/* /End replace */}
					{/* <CreateModal open={open} setOpen={setOpen} formType={currentModal} /> */}
				</div>
			</main>
		</div>
	);
}
