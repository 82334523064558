export const CREATE_EVENT = {
	name: "",
	link: "",
	staffPick: false,
	venue: "",
	address: "",
	startDate: "",
	startTime: "",
	endTime: "",
	freeFood: false,
	freeDrinks: false,
	notes: "",
};

export const USER_REGISTRATION = {
	age_group: [],
	austinLocal: false,
	city: "",
	confirmed: false,
	country: "",
	email: "",
	firstName: "",
	gender: "",
	lastName: "",
	zipCode: "",
	password: "",
};
