const UserEmptyState = () => {
	return (
		<div className="text-center">
			<svg
				className="mx-auto h-12 w-12 text-white"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				aria-hidden="true"
			>
				<path
					vectorEffect="non-scaling-stroke"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
				/>
			</svg>
			<h3 className="mt-2 text-sm font-medium text-white">
				Nothing to see here...
			</h3>
			<p className="mt-1 text-sm text-gray-200">
				Think there's an error with this?
			</p>
			<div className="mt-6">
				<a
					href="mailto:info@whenwherewhat.com"
					className="inline-flex items-center px-4 py-2 border border-white shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				>
					Email support
				</a>
			</div>
		</div>
	);
};

export default UserEmptyState;
