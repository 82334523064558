import { useMemo } from "react";
import { useTable } from "react-table";
import moment from "moment";
import { LocationMarkerIcon } from "@heroicons/react/solid";

import Emoji from "../Emoji";
import { TABLE_COLUMNS } from "../../_constants";

const BluelistTable = ({ columns, data }) => {
	const memoizedBluelistColumns = useMemo(
		() => [
			columns.priority && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[6].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[6],
				Cell: ({ value }) => {
					switch (value) {
						case "high":
							return (
								<div className="flex items-center">
									<Emoji symbol="🔥" label="fire" />
									<Emoji symbol="🔥" label="fire" />
									<Emoji symbol="🔥" label="fire" />
								</div>
							);
						case "medium":
							return (
								<div className="flex items-center">
									<Emoji symbol="🔥" label="fire" />
								</div>
							);
						case "low":
							return <></>;
						default:
							return <></>;
					}
				},
			},
			columns.name && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[0].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[0],
				Cell: ({ value, row }) => (
					<a
						className="font-bold text-primary hover:underline"
						href={row?.original?.link}
						target="_blank"
						rel="noreferrer"
					>
						{value}
					</a>
				),
			},
			columns.startDate && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[1].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[1],
				Cell: ({ value }) => (value ? moment(value).format("MMMM Do") : "N/A"),
			},
			columns.endDate && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[2].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[2],
				Cell: ({ value }) => (value ? moment(value).format("MMMM Do") : "N/A"),
			},
			columns.venue && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[3].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[3],
				Cell: ({ value, row }) => {
					const query = row?.original?.address
						? row?.original?.address?.split(" ")?.join("+")
						: value;
					return (
						<div className="flex flex-row">
							<p className="mr-2"> {value}</p>
							<a
								href={`http://maps.google.com/maps?q=${query},+Austin,+Texas`}
								target="_blank"
								rel="noreferrer"
							>
								<LocationMarkerIcon
									className="w-5 h-5 text-gray-400 hover:text-primary"
									aria-hidden="true"
								/>
							</a>
						</div>
					);
				},
			},
			columns.rsvpStatus && {
				Header: TABLE_COLUMNS.BLUELIST_COLUMNS[5].label,
				accessor: TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS[5],
				Cell: ({ value }) => value,
			},
		],
		[
			columns.name,
			columns.startDate,
			columns.endDate,
			columns.venue,
			columns.rsvpStatus,
			columns.priority,
		]
	);

	const memoizedData = useMemo(() => data, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns: memoizedBluelistColumns, data: memoizedData });

	return (
		<div className="flex flex-col">
			<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table
							{...getTableProps()}
							className="min-w-full divide-y divide-gray-200"
						>
							<thead className="bg-gray-50 ">
								{
									// Loop over the header rows
									headerGroups.map((headerGroup) => (
										// Apply the header row props
										<tr {...headerGroup.getHeaderGroupProps()}>
											{
												// Loop over the headers in each row
												headerGroup.headers.map((column) => (
													// Apply the header cell props
													<th
														scope="col"
														className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
														{...column.getHeaderProps()}
													>
														{
															// Render the header
															column.render("Header")
														}
													</th>
												))
											}
											{/* <th scope='col' className='relative px-6 py-3'>
                          <span className='sr-only'>Edit</span>
                        </th> */}
										</tr>
									))
								}
							</thead>
							<tbody
								{...getTableBodyProps()}
								className="bg-white divide-y divide-gray-300 "
							>
								{
									// Loop over the table rows
									rows.map((row, i) => {
										// Prepare the row for display
										prepareRow(row);
										return (
											// Apply the row props
											<tr
												{...row.getRowProps()}
												className={`${
													i % 2 === 0 ? "bg-white" : "bg-gray-100"
												} hover:bg-gray-200`}
											>
												{
													// Loop over the rows cells
													row.cells.map((cell) => {
														// Apply the cell props
														return (
															<td
																{...cell.getCellProps()}
																className="px-6 py-4 whitespace-nowrap text-sm text-black"
															>
																{
																	// Render the cell contents
																	cell.render("Cell")
																}
															</td>
														);
													})
												}
												{/* <td className='px-6 py-4 whitespace-nowrap text-right text-sm font-medium'>
                            <a href='#' className='text-primary hover:text-black'>
                              Edit
                            </a>
                          </td> */}
											</tr>
										);
									})
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BluelistTable;
