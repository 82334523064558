import { Controller } from "react-hook-form";
import ReactSelect from "react-select";

const Select = ({ control, name, isClearable, options, defaultValue }) => {
	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			render={({ field }) => (
				<ReactSelect
					defaultValue={defaultValue}
					defaultInputValue={defaultValue}
					{...field}
					onChange={(value) => field.onChange(value.value)}
					value={options.find((o) => o.value === field.value)}
					isClearable={isClearable}
					options={options}
				/>
			)}
		/>
	);
};

export default Select;
