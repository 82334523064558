import React, { useMemo, useState } from "react";
import queryString from "query-string";

import { useAuth } from "../../utils/auth";
// import useBluelistSponsor from "../../hooks/useBluelistSponsor";
import AdminHeader from "../../components/AdminHeader";
import { BluelistTable, UserEmptyState } from "../../components";

import { useBluelistEvents, useBluelist } from "../../hooks";
import { getBluelistItems } from "../../services/bluelist";
import { TABLE_COLUMNS } from "../../_constants";

const BluelistPage = () => {
	const params = queryString.stringify(
		{
			populate: "*",
			sort: "createdAt:desc",
		},
		{
			encodeValuesOnly: true,
		}
	);

	const auth = useAuth();

	const [page] = useState(1);
	const [pageSize] = useState(27);

	// const nextPage = () => setPage((prevState) => prevState + 1);

	// const prevPage = () => setPage((prevState) => prevState - 1);

	// const { logo, image, link, bluelistSponsorError } = useBluelistSponsor();

	const { bluelist } = useBluelist();

	const { bluelistData } = useBluelistEvents({
		params,
		page,
		pageSize,
		eventName: `${bluelist?.bluelist?.mainEvent} ${bluelist?.bluelist?.year}`,
		getBluelistItems,
	});

	const columns = useMemo(
		() =>
			TABLE_COLUMNS.INITIAL_BLUESPREADSHEET_COLUMNS.reduce(
				(acc, value) => ({ ...acc, [value]: value }),
				{}
			),
		[]
	);

	return (
		<>
			<div className="fixed w-screen h-screen flex flex-column px-10 sm:invisible lg:visible">
				{/* <div className="w-[50vw] flex justify-start items-center">
					{" "}
					<img src={image?.url} alt={image?.alt} className="w-[15vw] fixed" />
				</div>
				<div className="w-[50vw] flex justify-end items-center  ">
					{" "}
					<img src={image?.url} alt={image?.alt} className="w-[15vw] fixed" />
				</div> */}
			</div>
			<div className="py-5 mx-auto px-4 sm:px-6 lg:px-8 relative">
				{!!auth?.isAdmin && (
					<div className="py-5 mx-auto">
						<AdminHeader />
					</div>
				)}
				<div className="flex flex-col justify-center items-center text-white text-center">
					<h1 className="text-5xl md:text-7xl font-bold">
						{bluelist?.bluelist?.titile}
					</h1>
					<h2 className="text-2xl md:text-4xl font-medium">
						{bluelist?.bluelist?.subtitle}
					</h2>
					<h3>{bluelist?.bluelist?.description}</h3>
				</div>
				{/* <a href={link} rel="noopener noreferrer" target="_blank">
					<div className="grid place-items-center mb-7 ">
						{" "}
						<img
							src={logo?.url}
							alt={logo?.alt}
							className="w-[80vw] lg:w-[40vw]"
						/>
					</div>
				</a> */}
				<div className=" h-screen overflow-y-scroll mt-9">
					<>
						{bluelistData?.bluelistItems?.length > 0 ? (
							<BluelistTable
								columns={columns}
								data={bluelistData?.bluelistItems?.map((event) => {
									const newEvent = { ...event?.attributes, id: event.id };

									return newEvent;
								})}
							/>
						) : (
							<UserEmptyState />
						)}
					</>

					{/* <iframe
						className="w-[80vw] lg:w-[50vw] min-h-[75vh]"
						title="Bluelist Spreadsheet"
						src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRqf6d9LclpBiGXBtWyJ8rU4xmAWJx8cp_R2BO5Sbdt_o70GzUoZAQLe8As3aIiHcIgtJ79UFsDePt2/pubhtml?gid=294196710&amp;single=true&amp;widget=true&amp;headers=false"
					></iframe> */}
				</div>
			</div>
		</>
	);
};

export default BluelistPage;
