import React, { useMemo } from "react";
import queryString from "query-string";
import { useMyFavorites } from "../../hooks";
import { TABLE_COLUMNS, VIEW_TYPES } from "../../_constants";
import { Table, Cards, Pagination, UserEmptyState } from "..";

import { useAuth } from "../../utils/auth";
import { getMyFavorites, updateFavorites } from "../../services/users";

import { notification } from "../../services";

const MyFavoritesData = ({
	view,
	page,
	pageSize,
	currentSort,
	currentDate,
	nextPage,
	prevPage,
}) => {
	const { user, refreshUser } = useAuth();

	const params = queryString.stringify(
		{
			populate: "*",
		},
		{
			encodeValuesOnly: true,
		}
	);
	const userFavorites = user?.favorites ?? [];

	const { eventsData, mutate, data } = useMyFavorites({
		params,
		page,
		pageSize,
		currentSort,
		currentDate,
		getMyFavorites,
	});

	const handleFavoriteClick = async (eventId) => {
		try {
			let favorites = userFavorites.map((event) => event?.id);

			if (!!favorites.some((event) => event === eventId)) {
				// Remove from favorites
				const index = favorites.indexOf(eventId);

				if (index > -1) {
					favorites.splice(index, 1);
				}

				await updateFavorites({ userId: user?.id, eventIds: favorites });
			} else {
				// Add to favorites
				await updateFavorites({
					userId: user?.id,
					eventIds: [eventId, ...favorites],
				});
			}

			await refreshUser();

			mutate({
				data: {
					...data,
					favorites,
				},
			});
		} catch (error) {
			notification.error("Error adding/removing favorite");
			console.error("Error adding/removing favorite", error);
		}
	};

	const columns = useMemo(
		() =>
			TABLE_COLUMNS.INITIAL_EVENT_COLUMNS.reduce(
				(acc, value) => ({ ...acc, [value]: value }),
				{}
			),
		[]
	);

	const renderBlueprint = () => {
		switch (view) {
			case VIEW_TYPES.TABLE:
				return (
					<Table
						columns={columns}
						data={eventsData}
						favorites={userFavorites.map((event) => event?.id)}
						handleFavoriteClick={handleFavoriteClick}
					/>
				);
			case VIEW_TYPES.CARDS:
				return (
					<Cards
						data={eventsData}
						config={{
							params,
							page,
							pageSize,
							currentSort,
							currentDate,
							getMyFavorites,
						}}
					/>
				);

			default:
				break;
		}
	};
	return (
		<>
			<div className="py-8 sm:px-0">
				{eventsData?.length > 0 ? renderBlueprint() : <UserEmptyState />}
			</div>
			<Pagination
				page={page}
				perPage={pageSize}
				pageCount={eventsData?.meta?.pagination?.pageCount}
				total={eventsData?.meta?.pagination?.total}
				handleNext={nextPage}
				handlePrevious={prevPage}
			/>
		</>
	);
};

export default MyFavoritesData;
