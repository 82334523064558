import React, { useCallback, useState } from "react";
import { Switch } from "@headlessui/react";
import { Navigate } from "react-router-dom";

import moment from "moment";

import { useAuth } from "../../../utils/auth";
import { SortDropdown, BluePrintData } from "../../../components";

import { FESTIVAL_DAYS, VIEW_TYPES, SORT_OPTIONS } from "../../../_constants";
// import { notification } from "../../../services";
import { useEventsList } from "../../../hooks";
import { eventTimelineAdapter } from "../../../utils/adapters/eventList";

const BlueprintPage = () => {
	const auth = useAuth();
	const [view, setView] = useState(VIEW_TYPES.CARDS);
	const [page, setPage] = useState(1);
	const [pageSize] = useState(27);
	const [currentSort, setCurrentSort] = useState(SORT_OPTIONS[0].value);

	const { eventsList, eventsData, defaultEvent } = useEventsList();

	const [currentEvent, setCurrentEvent] = useState(defaultEvent);

	const getEventDays = useCallback(() => {
		const eventDays = eventTimelineAdapter(
			eventsData?.find((event) => event?.id === currentEvent?.id)
		);

		if (eventDays?.length > 10) {
			return eventDays;
		} else {
			return FESTIVAL_DAYS;
		}
	}, [currentEvent, eventsData]);

	const [currentDate, setCurrentDate] = useState(getEventDays()[0].value);

	const nextPage = () => setPage((prevState) => prevState + 1);
	const prevPage = () => setPage((prevState) => prevState - 1);

	const handleDayFilter = ({ value }) => {
		if (value === FESTIVAL_DAYS[0].value) {
			setCurrentDate(value);
			setPage(1);
		} else {
			const sanitizedDay = moment(value, "YYYY-MM-DD").format("YYYY-MM-DD");
			setCurrentDate(sanitizedDay);
			setPage(1);
		}
	};

	const handleSort = ({ value }) => {
		setCurrentSort(value);
		setPage(1);
	};

	const handleCurrentEvent = (option) => {
		setCurrentEvent(option);
		setPage(1);
	};

	if (auth?.isAdmin) {
		return <Navigate to="/admin" />;
	}

	return (
		<>
			<div className="flex sm:items-center justify-between">
				<div className="flex flex-col sm:flex-row mt-4">
					{eventsList && (
						<div className="mb-4 sm:mr-4">
							<SortDropdown
								label="Event"
								options={eventsList}
								onChange={handleCurrentEvent}
								defaultOption={defaultEvent}
							/>
						</div>
					)}
					<div className="mb-4 sm:mr-4">
						<SortDropdown
							label="Festival Day"
							options={getEventDays()}
							onChange={handleDayFilter}
						/>
					</div>
					<SortDropdown
						label="Sort By"
						options={SORT_OPTIONS}
						onChange={handleSort}
					/>
				</div>
				<div className="flex flex-col mt-4">
					<label className="block text-sm font-medium text-gray-300">{`${
						view === VIEW_TYPES.CARDS ? "Card" : "Table"
					} View`}</label>
					<ViewSwitch
						enabled={view === VIEW_TYPES.CARDS}
						setEnabled={setView}
					/>
				</div>
			</div>
			{eventsList && (
				<BluePrintData
					view={view}
					page={page}
					pageSize={pageSize}
					currentSort={currentSort}
					currentDate={currentDate}
					currentEvent={currentEvent || defaultEvent}
					nextPage={nextPage}
					prevPage={prevPage}
				/>
			)}
		</>
	);
};

export default BlueprintPage;

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

function ViewSwitch({ enabled, setEnabled }) {
	return (
		<Switch
			checked={enabled}
			onChange={() =>
				setEnabled((prevState) =>
					prevState === VIEW_TYPES.CARDS ? VIEW_TYPES.TABLE : VIEW_TYPES.CARDS
				)
			}
			className={classNames(
				enabled ? "bg-green-600" : "bg-gray-200",
				"mt-2 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
			)}
		>
			<span className="sr-only">Use setting</span>
			<span
				className={classNames(
					enabled ? "translate-x-5" : "translate-x-0",
					"pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
				)}
			>
				<span
					className={classNames(
						enabled
							? "opacity-0 ease-out duration-100"
							: "opacity-100 ease-in duration-200",
						"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
					)}
					aria-hidden="true"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-3 w-3 text-gray-400"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
							clipRule="evenodd"
						/>
					</svg>
				</span>
				<span
					className={classNames(
						enabled
							? "opacity-100 ease-in duration-200"
							: "opacity-0 ease-out duration-100",
						"absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
					)}
					aria-hidden="true"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-3 w-3 text-green-600"
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
					</svg>
				</span>
			</span>
		</Switch>
	);
}
