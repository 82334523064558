import React from "react";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate } from "react-router-dom";
import {
	Account,
	BluelistPage,
	// HomePage,
	LoginPage,
	MyFavorites,
	RegistrationPage,
	RequireAuth,
} from "..";
import { AuthProvider } from "../../utils/auth";
import { Layout } from "../../components";
import AdminDashboard from "../AdminDashboard";
import EventsPage from "../AdminDashboard/EventsPage";
// import SpreadSheetsPage from '../AdminDashboard/SpreadSheetsPage';
import UserDashboard from "../UserDashboard";
import BlueprintPage from "../UserDashboard/BlueprintPage";

import { usePageTracking } from "../../hooks";
import ForgotPassword from "../ForgotPasswordPage";
import ResetPassword from "../ResetPasswordPage";

function App() {
	usePageTracking();

	return (
		<AuthProvider>
			<Layout>
				<Routes>
					{/* <Route
						path="/"
						element={
							<RequireAuth redirectTo="/login">
								<HomePage />
							</RequireAuth>
						}
					/> */}
					{/* <Route path="/" element={<Navigate to="/blueprint" replace />} /> */}
					<Route path="reset-password" element={<ResetPassword />} />
					<Route path="forgot-password" element={<ForgotPassword />} />
					<Route path="login" element={<LoginPage />} />
					<Route path="register" element={<RegistrationPage />} />

					<Route
						path="admin"
						element={
							<RequireAuth redirectTo="/profile/blueprint" withAdminRights>
								<AdminDashboard />
							</RequireAuth>
						}
					>
						<Route index element={<EventsPage />} />
						<Route path="events" element={<EventsPage />} />
						{/* <Route path="spreadsheets" element={<SpreadSheetsPage />} /> */}
					</Route>
					<Route
						path="/"
						element={
							<RequireAuth redirectTo="/login">
								<UserDashboard />
							</RequireAuth>
						}
					>
						{/* <Route path="blueprint" element={<BlueprintPage />} /> */}
						<Route index element={<BlueprintPage />} />
						<Route path="favorites" element={<MyFavorites />} />
						<Route path="account" element={<Account />} />
					</Route>
					<Route
						path="bluelist"
						element={
							<RequireAuth redirectTo={"/login"}>
								<BluelistPage />
							</RequireAuth>
						}
					/>

					<Route path="*" element={<Navigate to="/" replace />} />
				</Routes>
			</Layout>

			<ToastContainer position="top-left" newestOnTop limit={5} />
		</AuthProvider>
	);
}

export default App;
