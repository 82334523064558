import QueryString from "qs";
import useSWR from "swr";
import moment from "moment";
import { users, events } from "../services";

const useAdminStats = () => {
	const staffPickQuery = QueryString.stringify(
		{
			filters: {
				staffPick: {
					$eq: true,
				},
				bluesheet: {
					id: {
						$eq: 8,
					},
				},
			},
		},
		{ encodeValuesOnly: true }
	);

	const bluesheetQuery = QueryString.stringify(
		{
			filters: {
				bluesheet: {
					id: {
						$eq: 8,
					},
				},
			},
		},
		{ encodeValuesOnly: true }
	);

	const last24HoursQuery = QueryString.stringify(
		{
			filters: {
				createdAt: {
					$gte: moment().subtract(1, "days").format("YYYY-MM-DD"),
				},
			},
		},
		{ encodeValuesOnly: true }
	);

	const { data: userCount, error: userCountError } = useSWR(
		"/users/count",
		users.getUserCount
	);

	const { data: eventCount, error: eventCountError } = useSWR(
		`/blue-sheet-events?${bluesheetQuery}`,
		events.getEventCount
	);

	const { data: staffPickCount, error: staffPickCountError } = useSWR(
		`/blue-sheet-events?${staffPickQuery}`,
		events.getStaffPickCount
	);

	const { data: last24Hours, error: last24HoursError } = useSWR(
		`/users?${last24HoursQuery}`,
		users.getUsersInLast24Hours
	);

	return {
		userCount,
		userCountError,
		last24Hours,
		last24HoursError,
		eventCount,
		eventCountError,
		staffPickCount,
		staffPickCountError,
	};
};

export default useAdminStats;
