import useSWR from "swr";
import { bluelist } from "../services";

const useBluelist = () => {
	const { data: bluelistData, error: bluelistError } = useSWR(
		"/bluelist?[populate]=*",
		bluelist.getBluelist
	);

	return {
		bluelist: bluelistData,
		bluelistError,
	};
};

export default useBluelist;
