import useSWR from "swr";

const useBluelistEvents = ({
	params,
	page,
	pageSize,
	eventName,
	getBluelistItems,
}) => {
	const { data, error } = useSWR(
		`/bluelist-items?${params}&pagination[page]=${page}&pagination[pageSize]=500&filters[event][$eq]=${eventName}`,
		getBluelistItems
	);

	return {
		bluelistData: data,
		error,
	};
};

export default useBluelistEvents;
