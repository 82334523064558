import httpClient from "../utils/httpClient";

export const getBluelistItems = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		return { bluelistItems: res?.data?.data, meta: res.data.meta };
	});
};

export const getSponsor = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		return { sponsor: res?.data?.data?.attributes, meta: res.data.meta };
	});
};

export const getBluelist = (url) => {
	return httpClient.get(`${url}`).then((res) => {
		return { bluelist: res?.data?.data?.attributes, meta: res.data.meta };
	});
};
