const FESTIVAL_DAYS = [
	{ id: 0, value: "*", label: "All" },
	// { id: 1, value: '2023-11-04', label: 'Friday, November 4' },
	// { id: 2, value: '2023-11-05', label: 'Saturday, November 5' },
	// { id: 3, value: '2023-11-06', label: 'Sunday, November 6' },
	// { id: 4, value: '2023-11-07', label: 'Monday, November 7' },
	// { id: 5, value: '2023-11-08', label: 'Tuesday, November 8' },
	// { id: 6, value: '2023-11-09', label: 'Wednesday, November 9' },
	// { id: 7, value: '2023-11-10', label: 'Friday, November 10' },
	// { id: 8, value: '2023-11-11', label: 'Saturday, November 11' },
	// { id: 9, value: '2023-11-12', label: 'Sunday, November 12' },
	// { id: 10, value: '2023-11-13', label: 'Sunday, November 13' },
	// { id: 11, value: '2023-11-14', label: 'Monday, November 14' },
	// { id: 12, value: '2023-11-15', label: 'Tuesday, November 15' },
	// { id: 13, value: '2023-11-16', label: 'Wednesday, November 16' },
	// { id: 14, value: '2023-11-17', label: 'Friday, November 17' },
	// { id: 15, value: '2023-11-18', label: 'Saturday, November 18' },
	// { id: 16, value: '2023-11-19', label: 'Sunday, November 19' }
	{ id: 1, value: "2024-03-08", label: "Friday, March 8" },
	{ id: 2, value: "2024-03-09", label: "Saturday, March 9" },
	{ id: 3, value: "2024-03-10", label: "Sunday, March 10" },
	{ id: 4, value: "2024-03-11", label: "Monday, March 11" },
	{ id: 5, value: "2024-03-12", label: "Tuesday, March 12" },
	{ id: 6, value: "2024-03-13", label: "Wednesday, March 13" },
	{ id: 7, value: "2024-03-14", label: "Thursday, March 14" },
	{ id: 8, value: "2024-03-15", label: "Friday, March 15" },
	{ id: 9, value: "2024-03-16", label: "Saturday, March 16" },
	{ id: 10, value: "2024-03-17", label: "Sunday, March 17" },
];
export default FESTIVAL_DAYS;
