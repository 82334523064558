import React from "react";
import { useForm } from "react-hook-form";

import { useAuth } from "../../../utils/auth";
import { updateUser } from "../../../services/users";
import { notification } from "../../../services";

const Account = () => {
	const { user, refreshUser } = useAuth();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues: {
			firstName: user?.firstName,
			lastName: user?.lastName,
			email: user?.email,
		},
		mode: "onChange",
	});

	const onSubmit = async (data) => {
		try {
			await updateUser(data);
			await refreshUser();
			notification.success("Account updated successfully");
		} catch (error) {
			notification.error("An error occurred while updating your account");
			console.error("An error occurred while updating your account", error);
		}
	};

	return (
		<div className="divide-y divide-white/5">
			<div className="grid grid-cols-1 gap-x-8 gap-y-10  py-16  md:grid-cols-3 ">
				<div>
					<h2 className="text-base font-semibold leading-7 text-white">
						Personal Information
					</h2>
					<p className="mt-1 text-sm leading-6 text-gray-400">
						Use a permanent address where you can receive mail.
					</p>
				</div>
				<form className="md:col-span-2" onSubmit={handleSubmit(onSubmit)}>
					<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
						<div className="sm:col-span-3">
							<label
								htmlFor="first-name"
								className="block text-sm font-medium leading-6 text-white"
							>
								First name
							</label>
							<div className="mt-2">
								<input
									{...register("firstName")}
									defaultValue={user?.firstName}
									className="block w-full rounded-md border-0  py-1.5 text-black bg-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3"
								/>
								{errors.firstName && (
									<span className="inline-block mt-2 text-red-500">
										First name is required
									</span>
								)}
							</div>
						</div>

						<div className="sm:col-span-3">
							<label
								htmlFor="last-name"
								className="block text-sm font-medium leading-6 text-white"
							>
								Last name
							</label>
							<div className="mt-2">
								<input
									{...register("lastName")}
									defaultValue={user?.lastName}
									className="block w-full rounded-md border-0  py-1.5 text-black bg-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3"
								/>
								{errors.lastName && (
									<span className="inline-block mt-2 text-red-500">
										Last name is required
									</span>
								)}
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="email"
								className="block text-sm font-medium leading-6 text-white"
							>
								Email address
							</label>
							<div className="mt-2">
								<input
									{...register("email", { required: true })}
									defaultValue={user?.email}
									autoComplete="email"
									className="block w-full rounded-md border-0  py-1.5 text-black bg-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 px-3"
								/>
								{errors.email && (
									<span className="inline-block mt-2 text-red-500">
										Email is required
									</span>
								)}
							</div>
						</div>

						{/* <div className="col-span-full">
							<label
								htmlFor="gender"
								className="block text-sm font-medium leading-6 text-white"
							>
								Gender
							</label>
							<div className="mt-2">
								<Select control={control} name="gender" options={GENDERS} />
							</div>
						</div>

						<div className="col-span-full">
							<label
								htmlFor="timezone"
								className="block text-sm font-medium leading-6 text-white"
							>
								Age group
							</label>
							<div className="mt-2">
								{data && (
									<Select
										control={control}
										name="age_group"
										options={data?.ageGroups}
									/>
								)}
							</div>
						</div> */}
					</div>

					<div className="mt-8 flex">
						<button
							type="submit"
							className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-black bg-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed"
						>
							Save
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Account;
