import useSWR from "swr";
import { eventListAdapter } from "../utils/adapters/eventList";
import { getEventsList } from "../services/events";

const useEventsList = () => {
	const { data, error } = useSWR(`/spreadsheets?`, getEventsList);

	return {
		eventsList: eventListAdapter(data?.eventsList),
		eventsData: data?.eventsList,
		meta: data?.meta,
		error,
		defaultEvent: eventListAdapter(data?.eventsList)?.find(
			(event) => event?.default === true
		),
	};
};

export default useEventsList;
