import Nav from "./Nav";
import Footer from "./Footer";

const Layout = ({ children }) => {
	return (
		<>
			<Nav />
			<div className=" bg-primary min-h-[85vh] w-[100vw]">
				{/* Content goes here */}
				{children}
			</div>
			<Footer />
		</>
	);
};

export default Layout;
