import { Link, useLocation } from "react-router-dom";

export default function UserHeader({ children }) {
	const { pathname } = useLocation();

	const tabs = [
		{
			name: "Blueprint",
			to: "/blueprint",
			current: pathname === "/blueprint" || pathname === "/",
		},
		{
			name: "My Favorites",
			to: "/favorites",
			current: pathname === "/favorites",
		},
		{
			name: "Account",
			to: "/account",
			current: pathname === "/account",
		},
	];
	return (
		<div className="relative border-y border-gray-200 sm:pb-0">
			{/* <div className="md:flex md:items-center md:justify-between">
				<h3 className="text-3xl leading-6 font-medium text-white mb-4">
					Welcome back, {user?.firstName}!
				</h3>
				{children}
			</div> */}
			<div className="mt-4">
				<div className="block">
					<nav className="-mb-px flex space-x-8">
						{tabs.map((tab) => (
							<Link
								key={tab.name}
								to={tab.to}
								className={`${
									tab.current
										? "border-white text-white"
										: "border-transparent text-gray-300 hover:text-gray-500 hover:border-white"
								}
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                `}
								aria-current={tab.current ? "page" : undefined}
							>
								{tab.name}
							</Link>
						))}
					</nav>
				</div>
			</div>
		</div>
	);
}
