import { uniqueId } from "lodash";
import moment from "moment";

export const eventAdapter = ({ id, attributes }) => {
	return {
		id,
		value: id,
		label: `${attributes?.event} - ${attributes?.year}`,
		default: attributes?.defaultEvent === null ? false : true,
	};
};

export const eventListAdapter = (eventList) => {
	return eventList?.map((event) => eventAdapter({ ...event }));
};

export const eventTimelineAdapter = (event) => {
	const dates = enumerateDaysBetweenDates(
		event?.attributes?.startDate,
		event?.attributes?.endDate
	);

	const EVENT_DAYS = dates?.map((date) => ({
		id: uniqueId(),
		value: date,
		label: moment(date).format("dddd, MMMM Do"),
	}));
	EVENT_DAYS.unshift({ id: uniqueId(), value: "*", label: "All" });

	return EVENT_DAYS;
};

const enumerateDaysBetweenDates = (startDate, endDate) => {
	const dates = [];

	const currDate = moment(startDate);
	const lastDate = moment(endDate);

	dates.push(currDate.clone().format("YYYY-MM-DD"));

	while (currDate.add(1, "days").diff(lastDate) < 0) {
		dates.push(currDate.clone().format("YYYY-MM-DD"));
	}

	dates.push(lastDate.clone().format("YYYY-MM-DD"));

	return dates;
};
